/**
 * Màn hình hiển thị danh sách admin
 */
import { useEffect } from "react";
import {
  Button,
  Popconfirm,
  Table,
  Typography,
  Space,
  notification,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { ModalInfoType, PayloadType, AdminDataType } from "type";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";
import { setModal } from "features/modalSlice";
import CUUser from "pages/AdminUser/ListUser/CUUser";
import { useSelector } from "react-redux";
import _ from "lodash";
import { getUsers, setQuery, updateUser } from "features/userSlice";
import queryString from "query-string";
import { getRoleByName } from "features/roleSlice";
import { ACCOUNT_TYPE } from "const";
const { Title } = Typography;

const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover-inner {
    max-width: 240px !important;
    background-color: red;
  }
`;
function listAdmin() {
  const dispatch = useDispatch();
  const { query, users } = useSelector((state: any) => state.userReducer);
  const { role } = useSelector((state: any) => state.roleReducer);

  //Lấy danh sách tài khoản
  function handleGetUsers() {
    if (!role || _.get(role, "name", "") !== "admin") {
      const payload: PayloadType = {
        params: "admin",
        callback: {
          success() {
            dispatch(
              setQuery({
                ...query,
                populate: "roleId",
                sortBy: "createdAt:desc",
              })
            );
          },
          failed() {
            notification.error({
              message: "Không thể cập nhật thông tin người dùng!",
              description: "Thiếu quyền!",
            });
          },
        },
      };
      dispatch(getRoleByName(payload));
      return;
    }
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success() {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getUsers(payload));
  }
  useEffect(() => {
    handleGetUsers();
  }, [query]);

  const columns: ColumnsType<AdminDataType> = [
    {
      title: "Tên đăng nhập",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Họ tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "name",
    },
    {
      title: "Nhóm tài khoản",
      dataIndex: ["roleId", "name"],
      key: "roleId.name",
      align: "center",
    },
    {
      title: "Loại tài khoản",
      dataIndex: "accountType",
      key: "accountType",
      align: "center",
      render: (val) => {
        switch (val) {
          case ACCOUNT_TYPE.ADMIN:
            return "Quản trị";
          case ACCOUNT_TYPE.TECH:
            return "Nhập liệu";
          case ACCOUNT_TYPE.SUPPORT:
            return "Hỗ trợ";
          case ACCOUNT_TYPE.USER:
            return "Người dùng";
          default:
            return "";
        }
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "left",
      render: (val) => {
        return <>{!(val === "active") ? "Khóa" : "Hoạt động"}</>;
      },
    },
    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "left",
      render: (_, record: any) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                const payload: ModalInfoType = {
                  open: true,
                  title: "Cập nhật thông tin tài khoản quản trị",
                  content: (
                    <CUUser record={record} idUpdate={record?.id ?? false} />
                  ),
                  size: 700,
                };
                dispatch(setModal(payload));
              }}
            >
              Sửa
            </a>
            <CustomPopconfirm
              title={`Bạn có chắc chắn muốn ${
                record?.status === "active" ? "khóa" : "mở khóa"
              } tài khoản ${record?.name ?? ""}  không ?`}
              okText={"Có"}
              cancelText={"Không"}
              onConfirm={() => {
                const id = record?.id ?? "";
                if (id) {
                  const payload: PayloadType = {
                    params: id,
                    body: {
                      email: record?.email,
                      name: record?.name,
                      phone: record?.phone,
                      roleId: record.roleId.id,
                      status: record?.status === "active" ? "lock" : "active",
                    },
                    callback: {
                      success: () => {
                        notification.success({
                          message:
                            record?.status === "active"
                              ? "Khóa tài khoản người dùng thành công!"
                              : "Mở khóa tài khoản người dùng thành công!",
                        });
                        handleGetUsers();
                      },
                      failed(errorMessage) {
                        notification.error({
                          message: `${
                            record?.status === "active" ? "Khóa" : "Mở khóa"
                          } tài khoản người dùng thành công!`,
                          description: errorMessage,
                        });
                      },
                    },
                  };
                  dispatch(updateUser(payload));
                }
              }}
            >
              <a>{record?.status === "active" ? "Khóa" : "Mở khóa"}</a>
            </CustomPopconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div
        className="space-between"
        style={{
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
        <Space className="custom-placeholder-select">
          <Button
            type="primary"
            onClick={() => {
              const payload: ModalInfoType = {
                open: true,
                title: "Thêm mới tài khoản Admin",
                content: <CUUser />,
                dataUpdate: "",
                size: 700,
              };
              dispatch(setModal(payload));
            }}
          >
            <PlusOutlined />
            Thêm mới
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={_.get(users, "results", [])}
        rowClassName={(record: any) =>
          record?.status === "active" ? "table-row-open" : "table-row-close"
        }
        pagination={{
          total: _.get(users, "totalResults", []),
          current: _.get(query, "page", 1),
          pageSize: _.get(query, "limit", 10),
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"],
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            if (_.get(query, "limit", 0) !== pageSize) {
              dispatch(
                setQuery({
                  ...query,
                  page: 1,
                  limit: pageSize,
                })
              );
              return;
            }
            dispatch(
              setQuery({
                ...query,
                page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300 }}
      />
    </>
  );
}

export default listAdmin;
