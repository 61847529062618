/**
 * Quản lí danh sách trại
 */
import { useState, useEffect } from "react";
import {
  Table,
  Typography,
  notification,
  Input,
  Space,
  Select,
  Modal,
  Button,
  Popover,
} from "antd";
import type { ColumnsType } from "antd/es/table";
const { Search } = Input;
import { useHistory } from "react-router";
import { PayloadType, MangerHerdType, ModalInfoType } from "type";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import { getSubscribers, setQuery } from "features/subscriberSlice";
import queryString from "query-string";
import CUHerd from "./CUHerd";
import { setModal } from "features/modalSlice";
import moment from "moment";
import {
  getNotificationTemplates,
  seandNotification,
} from "features/userSlice";
import TextArea from "antd/lib/input/TextArea";
const { Title } = Typography;

function ListPigFarm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { query, subscribers } = useSelector(
    (state: any) => state.subscriberReducer
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [notificationModal, setNotificationModal] = useState(false);
  const [contentNotification, setContentNotification] = useState("");
  const [titleNotification, setTitleNotification] = useState("");
  const [selectNotification, setSelectNotification] = useState(undefined);
  const [searchText, setSearchText] = useState<string>("");

  //Lấy danh sách trại theo tài khoản người dùng
  function getHerdByUser() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          ...query,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success() {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getSubscribers(payload));
  }
  useEffect(() => {
    getHerdByUser();
  }, [query]);

  const handleSearch = () => {
    dispatch(
      setQuery({
        ...query, // lấy lại những thuộc tính về query ban đầu được lấy từ useReducer
        phone: searchText,
        page: 1, //Trường lọc data là name và
      })
    );
  };

  const columns: ColumnsType<MangerHerdType> = [
    {
      title: "Tên chủ trại",
      dataIndex: "name",
      key: "name",
      width: 400,
      render(value, record) {
        const notificationDate =
          _.get(record, "notificationsHistory", [])[0]?.date ?? "";
        const notificationTitle =
          _.get(record, "notificationsHistory", [])[0]?.notificationTitle ?? "";
        const notificationContent =
          _.get(record, "notificationsHistory", [])[0]?.notificationBody ?? "";
        return (
          <div>
            <div>{value ?? ""}</div>
            {notificationDate && (
              <div>
                Cập nhật thông báo{" "}
                {moment(notificationDate).format("DD/MM/YYYY")}
                <Popover
                  content={
                    <div>
                      <div>Tiêu đề :{notificationTitle}</div>
                      <div>Nội dung :{notificationContent}</div>
                    </div>
                  }
                  title=""
                  trigger="click"
                >
                  <QuestionCircleTwoTone style={{ marginLeft: "4px" }} />
                </Popover>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      width: 250,
    },

    {
      title: "Số trại con",
      dataIndex: "count",
      key: "count",
      align: "center",
      width: 150,
      render: (_, record: any) => {
        return (
          <p style={{ textAlign: "right" }}>
            {record?.personalConfig?.herdIds.length}
          </p>
        );
      },
    },
    {
      title: "Địa chỉ ",
      dataIndex: "address",
      key: "address",
      width: 400,
      render: (str, row) => {
        const province = _.get(row, "province", "");
        const district = _.get(row, "district", "");
        const detailAddress = _.get(row, "detailAddress", "");
        return [province, district, detailAddress].filter(Boolean).join(", ");
      },
    },
    {
      title: "Số lợn nái",
      dataIndex: "numberOfSows",
      key: "numberOfSows",
      align: "center",
      width: 200,
      render: (str) => str || 0,
    },
    {
      title: "Số lợn thịt",
      dataIndex: "numOfMeatPigs",
      key: "numOfMeatPigs",
      align: "center",
      width: 200,
      render: (str) => str || 0,
    },
    {
      title: "Ngày đăng ký",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 200,
      render: (str) => moment(str).format("DD/MM/YYYY"),
    },
    {
      title: "Ngày cập nhật cuối",
      dataIndex: "latestWarehouseHistory",
      key: "latestWarehouseHistory",
      align: "center",
      width: 200,
      sorter: true,
      render: (str) => (str ? moment(str).format("DD/MM/YYYY") : "-"),
      onHeaderCell: (column) => ({
        onClick: () => {
          let newSortBy;

          if (query.sortBy === "latestWarehouseHistory:asc") {
            newSortBy = "latestWarehouseHistory:desc";
          } else if (query.sortBy === "latestWarehouseHistory:desc") {
            newSortBy = "createdAt:desc"; // Quay về mặc định
          } else {
            newSortBy = "latestWarehouseHistory:asc";
          }

          dispatch(
            setQuery({
              ...query,
              sortBy: newSortBy,
              page: 1, // Reset lại trang khi thay đổi sắp xếp
            })
          );
        },
      }),
    },

    {
      title: "Kỹ thuật hỗ trợ",
      dataIndex: "techSupporter.name",
      key: "techSupporter.name",
      width: 300,
    },

    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      align: "left",
      width: 250,
      render: (_, record: any) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                history.push({
                  pathname: "/admin/pig-farm-info/" + record?.id,
                  state: record,
                });
              }}
            >
              Xem
            </a>
            <a
              onClick={() => {
                const payload: ModalInfoType = {
                  open: true,
                  title: "Thêm trại ",
                  content: (
                    <CUHerd getHerdByUser={getHerdByUser} record={record} />
                  ),
                  dataUpdate: "",
                  size: 1200,
                };
                dispatch(setModal(payload));
              }}
            >
              Thêm trại con
            </a>
          </Space>
        );
      },
    },
  ];
  const { notificationTemplates } = useSelector(
    (state: any) => state.userReducer
  );

  // checkbox table
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: any = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    if (notificationModal) handleGetNotificationTemplates();
  }, [notificationModal]);

  function handleGetNotificationTemplates() {
    const payload: PayloadType = {
      query: queryString.stringify(query, {
        skipNull: true,
        skipEmptyString: true,
      }),
      skipUpdateReducer: false,
      callback: {
        success() {},
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getNotificationTemplates(payload));
  }
  const showModal = () => {
    setNotificationModal(true);
  };
  const [loadding, setLoadding] = useState(false);
  const handleOk = async () => {
    setLoadding(true);
    const payload: PayloadType = {
      body: {
        users: selectedRowKeys.map((item: any) => {
          return {
            userId: item,
            content: contentNotification,
            title: titleNotification,
          };
        }),
      },

      callback: {
        success(resultHerdCreate) {
          notification.success({
            message: "Gửi thông báo thành công!",
          });
          setContentNotification("");
          setTitleNotification("");
          setSelectNotification(undefined);
          setSelectedRowKeys([]);
          setNotificationModal(false);
          setLoadding(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Gửi thông báo thất bại!",
            description: errorMessage,
          });
          setLoadding(false);
        },
      },
    };
    await dispatch(seandNotification(payload));
  };

  const handleCancel = () => {
    setContentNotification("");
    setTitleNotification("");
    setNotificationModal(false);
    setSelectNotification(undefined);
  };
  return (
    <>
      <div
        style={{
          marginTop: 12,
          marginBottom: 24,
        }}
      >
        <Title level={5}>Danh sách</Title>
        <div
          style={{
            marginTop: 12,
            marginBottom: 24,
            display: "flex",
            justifyContent: "right",
          }}
        >
          {selectedRowKeys.length ? (
            <Button
              type="primary"
              onClick={() => {
                setNotificationModal(true);
              }}
            >
              Gửi thông báo
            </Button>
          ) : (
            ""
          )}
          <Space
            className="custom-placeholder-select"
            style={{ marginLeft: 4 }}
          >
            <Search
              placeholder="Tìm theo mã tài khoản"
              onChange={(e) => setSearchText(e.target.value)}
              onSearch={handleSearch}
              style={{ width: 252 }}
            />
          </Space>
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={"id"}
        rowSelection={rowSelection}
        dataSource={_.get(subscribers, "results", [])}
        pagination={{
          total: _.get(subscribers, "totalResults", []),
          current: _.get(query, "page", 1),
          pageSize: _.get(query, "limit", 10),
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          showTotal: (total, range) => {
            return `Có tất cả ${total} bản ghi`;
          },
          onChange(page, pageSize) {
            if (_.get(query, "limit", 0) !== pageSize) {
              dispatch(
                setQuery({
                  ...query,
                  page: 1,
                  limit: pageSize,
                })
              );
              return;
            }
            dispatch(
              setQuery({
                ...query,
                page,
                limit: pageSize,
              })
            );
          },
        }}
        scroll={{ x: 1300 }}
      />
      <Modal
        title="Gửi thông báo"
        open={notificationModal}
        onOk={handleOk}
        onCancel={handleCancel}
        width={680}
        confirmLoading={loadding}
        okText="Lưu"
        cancelText="Hủy"
      >
        <Title level={3}>Thông báo mẫu</Title>
        <Select
          showSearch
          allowClear
          style={{ width: "100%" }}
          placeholder="Chọn mẫu thông báo"
          optionFilterProp="children"
          value={selectNotification}
          options={_.get(notificationTemplates, "results", []).map(
            (item: any) => ({
              label: _.get(item, "title", ""),
              value: item.id,
            })
          )}
          onChange={(value) => {
            // Tìm phần tử được chọn
            const selectedItem = _.find(
              _.get(notificationTemplates, "results", []),
              { id: value }
            );

            // Cập nhật nội dung vào TextArea
            setContentNotification(_.get(selectedItem, "content", ""));
            setTitleNotification(_.get(selectedItem, "title", ""));
            setSelectNotification(value);
          }}
        />
        <Title level={3} style={{ marginTop: "12px" }}>
          Tiêu đề
        </Title>
        <TextArea
          rows={4}
          value={titleNotification}
          onChange={(e) => setTitleNotification(e.target.value)}
        />
        <Title level={3} style={{ marginTop: "12px" }}>
          Nội dung
        </Title>
        <TextArea
          rows={4}
          value={contentNotification}
          onChange={(e) => setContentNotification(e.target.value)}
        />
      </Modal>
    </>
  );
}

export default ListPigFarm;
