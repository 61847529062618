import {
  Button,
  Form,
  Input,
  Select,
  Col,
  DatePicker,
  Row,
  notification,
  InputNumber,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { setModal } from "features/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { PayloadType, SelectOptionType } from "type";
import { closeModal, getYupSync } from "utils";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { getPromotionDetails } from "features/promotionDetailSlice";
import _ from "lodash";
import queryString from "query-string";

import { getSubscribers } from "features/subscriberSlice";
import { getPromotions } from "features/promotionsSlice";
import { getServicePackages } from "features/servicePackageSlice";
import moment from "moment";
const dateFormat = "DD/MM/YYYY";
import {
  addPackService,
  caculateContracts,
  createContracts,
  getContractByStatus,
} from "features/contractSlice";
function CUContract({
  getContractByStatuss,
  isAddServicePack,
  onClose,
  onUpdate,
  data,
}: any) {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { query } = useSelector((state: any) => state.contractReducer);
  const [loading, setLoading] = useState(false);
  const { open } = useSelector((state: any) => state.modalReducer);
  const [listServicePack, setListServicePacks] = useState<SelectOptionType[]>(
    []
  );

  const [listPromotion, setListPromotion] = useState<SelectOptionType[]>([]);
  const [listPromotionDetail, setListPromotionDetail] = useState<
    SelectOptionType[]
  >([]);
  const [listUser, setListUser] = useState<SelectOptionType[]>([]);
  const [timeStart, setTimeStart] = useState(false);

  //Lấy danh sách tài khoản người dùng
  function handleGetUser() {
    const payload: PayloadType = {
      query: queryString.stringify(
        {
          limit: 99999,
          populate: "",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success(val) {
          const results = _.get(val, "results", []);
          if (!!results && Array.isArray(results)) {
            setListUser(
              results.map((item: any) => {
                return {
                  label: item.phone,
                  value: item.id,
                  ...item,
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getSubscribers(payload));
  }

  useEffect(() => {
    handleGetUser();
  }, []);

  function getContractByTabStatus(queryActive: any) {
    const payload = {
      query: queryString.stringify(
        {
          ...query,
          ...queryActive,
          populate:
            "contractDetails.servicePackageId.farmScaleTTNId farmScaleTTTId, userId",
          sortBy: "createdAt:desc",
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ),
      skipUpdateReducer: false,
      callback: {
        success() {},
        failed(errorMessage: any) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách!",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getContractByStatus(payload));
  }

  // lấy danh sách  gói dịch vụ
  const getListServicePacks = () => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 9999,
      }),
      skipUpdateReducer: true,
      callback: {
        success(typeGroups) {
          const results = _.get(typeGroups, "results", []);
          if (!!results && Array.isArray(results)) {
            setListServicePacks(
              results.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                  ...item,
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách admin! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getServicePackages(payload));
  };
  useEffect(() => {
    getListServicePacks();
  }, []);

  // lấy danh sách gói khuyến mãi
  const getListPromotion = (dateStart: string) => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 9999,
        date: dateStart,
      }),
      skipUpdateReducer: true,
      callback: {
        success(typeGroups) {
          const results = _.get(typeGroups, "results", []);
          if (!!results && Array.isArray(results)) {
            setListPromotion(
              results.map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách admin! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getPromotions(payload));
  };

  // Lấy chi tiết gói khuyến mãi theo ID của chương trình khuyến mãi
  const getListPromotionDetail = (idPromotion: string) => {
    const payload: PayloadType = {
      query: queryString.stringify({
        page: 1,
        limit: 9999,
        promotionId: idPromotion,
        populate: "servicePackId, promotionId",
        date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      }),
      skipUpdateReducer: true,
      callback: {
        success(typeGroups) {
          const results = _.get(typeGroups, "results", []);
          if (!!results && Array.isArray(results)) {
            setListPromotionDetail(
              results.map((item: any) => {
                return {
                  label: item.code,
                  value: item.id,
                };
              })
            );
          }
        },
        failed(errorMessage) {
          notification.error({
            message: "Có lỗi xảy ra trong quá trình lấy danh sách admin! ",
            description: errorMessage,
          });
        },
      },
    };
    dispatch(getPromotionDetails(payload));
  };

  const formSchema = yup.object().shape({
    userId: yup.string().required("Tên đăng nhập không được để trống!"),
    startDateContract: yup
      .string()
      .required("Vui lòng chọn thời gian bắt đầu!"),

    servicePackageId: yup.string().required("Vui lòng chọn gói dịch vụ"),
    time: yup.number().required("Vui lòng nhập thời hạn (tháng)"),
    inputReduce: yup.number(),
    endDateNotificationWarn: yup
      .string()
      .required("Vui lòng chọn thời gian bắt đầu cảnh báo!"),
    endDateCloseAccount: yup
      .string()
      .required("Vui lòng chọn thời gian bắt đầu đóng tài khoản!"),
  });

  useEffect(() => {
    form.resetFields();
  }, [open]);

  const yupSync = [getYupSync(formSchema)];

  async function handleSubmit() {
    const values = form.getFieldsValue();
    if (!isAddServicePack) {
      handleCreateConstract(values);
      return;
    }
    handleUpdateConstract(values);
  }

  function handleCreateConstract(values: any) {
    setLoading(true);
    const payload: PayloadType = {
      body: {
        servicePackageId: _.get(values, "servicePackageId"),
        promotionId: _.get(values, "promotionId", null),
        promotionDetailId: _.get(values, "promotionDetailId", null),
        startDateContract: moment(
          _.get(values, "startDateContract", "")
        ).toISOString(), // iso date
        servicePackageSupportIds: _.get(values, "servicePackageSupportIds", []),
        time: _.get(values, "time", 0),
        userId: _.get(values, "userId"),
        inputReduce: _.get(values, "inputReduce", 0),
        endDateCloseAccount: _.get(values, "endDateCloseAccount"),
        endDateNotificationWarn: _.get(values, "endDateNotificationWarn"),
      },
      callback: {
        success() {
          notification.success({
            message: "Tạo mới hợp đồng thành công!",
          });
          form.resetFields();
          getContractByTabStatus({ statusQuery: "trial" });
          getContractByStatuss();
          if (!isAddServicePack) {
            closeModal(dispatch, setModal);
          } else {
            onClose();
          }
          setLoading(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Không thể  tạo mới hợp đồng!",
            description: errorMessage,
          });
          setLoading(false);
        },
      },
    };
    dispatch(createContracts(payload));
  }
  function handleUpdateConstract(values: any) {
    setLoading(true);
    const payload: PayloadType = {
      params: data.id,
      body: {
        servicePackageId: _.get(values, "servicePackageId"),
        promotionId: _.get(values, "promotionId", null),
        promotionDetailId: _.get(values, "promotionDetailId", null),
        startDateContract: moment(
          _.get(values, "startDateContract", "")
        ).toISOString(), // iso date
        servicePackageSupportIds: _.get(values, "servicePackageSupportIds", []),
        time: _.get(values, "time", 0),
        inputReduce: _.get(values, "inputReduce", 0),
        endDateCloseAccount: _.get(values, "endDateCloseAccount"),
        endDateNotificationWarn: _.get(values, "endDateNotificationWarn"),
      },
      callback: {
        success(values) {
          notification.success({
            message: "Tạo mới gói thuê bao thành công!",
          });
          form.resetFields();
          onUpdate(values.data);
          getContractByStatuss();
          if (!isAddServicePack) {
            closeModal(dispatch, setModal);
          } else {
            onClose();
          }
          setLoading(false);
        },
        failed(errorMessage) {
          notification.error({
            message: "Cập nhật gói thuê bao thất bại!",
            description: errorMessage,
          });
          setLoading(false);
        },
      },
    };
    dispatch(addPackService(payload));
  }

  const caculateDataServices = () => {
    const values = form.getFieldsValue([
      "userId",
      "servicePackageId",
      "promotionId",
      "promotionDetailId",
      "startDateContract",
      "time",
      "inputReduce",
      "servicePackageSupportIds",
    ]);
    if (
      !_.isEmpty(values.servicePackageId) &&
      !_.isEmpty(values.startDateContract) &&
      _.isNumber(values.time) &&
      _.isNumber(values.inputReduce)
    ) {
      if (_.isUndefined(form.getFieldValue("promotionId"))) {
        form.setFieldValue("promotionId", null);
      }
      const bodyCaculate = {
        servicePackageId: _.get(values, "servicePackageId", []),
        promotionId: _.get(values, "promotionId", null),
        promotionDetailId: _.get(values, "promotionDetailId", null),
        startDateContract: moment(values?.startDateContract).toISOString(), // iso date
        servicePackageSupportIds: _.get(
          values,
          "servicePackageSupportIds",
          undefined
        ),
        time: values.time,
        inputReduce: values.inputReduce,
      };
      const payload: PayloadType = {
        body: bodyCaculate,
        callback: {
          success(val) {
            form.setFieldValue("timeUse", val.numOfMonth);
            form.setFieldValue("endDateContract", moment(val.endDateContract));
            form.setFieldValue(
              "endDateCloseAccount",
              moment(val.endDateCloseAccount)
            );
            form.setFieldValue(
              "endDateNotificationWarn",
              moment(val.endDateNotificationWarn)
            );
            form.setFieldValue("remainMoney", val.remainMoney);
            form.setFieldValue(
              "totalMoneySubscriber",
              val.totalMoneySubscriber
            );
            form.setFieldValue("totalMoneySupport", val.totalMoneySupport);
            form.setFieldValue("reduce", val.reduce);
          },
          failed(errorMessage) {
            notification.error({
              message: "Có lỗi xảy ra trong quá trình lấy danh sách! ",
              description: errorMessage,
            });
          },
        },
      };
      dispatch(caculateContracts(payload));
    }
  };

  const initValues = {
    userId: data?.userId.id,
    servicePackageId: undefined,
    promotionId: undefined,
    promotionDetailId: undefined,
    startDateContract: undefined,
    name: undefined,
    timeUse: undefined,
    endDateContract: undefined,
    remainMoney: "",
    servicePackageSupportIds: undefined,
    time: 1,
    inputReduce: 0,
    endDateNotificationWarn: undefined,
    endDateCloseAccount: undefined,
    totalMoneySubscriber: 0,
    totalMoneySupport: 0,
    reduce: 0,
  };

  function moneyFormat(num: number) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const labelValue = (label: string, value: number, isMinus?: boolean) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: 8,
          paddingTop: 8,
        }}
      >
        <span
          style={
            {
              // fontSize:
            }
          }
        >
          {" "}
          &#8226; {label}
        </span>
        <span
          style={
            {
              // fontSize:
            }
          }
        >
          {isMinus ? "-" : ""}
          {moneyFormat(value)} đ
        </span>
      </div>
    );
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      onFinish={() => {
        handleSubmit();
      }}
      initialValues={initValues}
      autoComplete="off"
    >
      <div className="ant-modal-body">
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {!isAddServicePack ? (
              <>
                <Form.Item
                  required
                  label={"Tên đăng nhập"}
                  name={"userId"}
                  rules={yupSync}
                >
                  <Select
                    showSearch
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Nhập SĐT để tìm kiếm"
                    optionFilterProp="children"
                    onChange={(e: any) => {
                      const dataNameByUser: any = listUser.find(
                        (item) => item.id === e
                      );
                      const nameUser = _.get(dataNameByUser, "name", "");
                      form.setFieldValue("name", nameUser);
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={listUser}
                  />
                </Form.Item>
                <Form.Item label={"Tên chủ trại"} name={"name"}>
                  <Input placeholder="" disabled style={{ color: "#000" }} />
                </Form.Item>
              </>
            ) : null}

            <Form.Item
              required
              label={"Gói thuê bao"}
              name={"servicePackageId"}
              rules={yupSync}
            >
              <Select
                showSearch
                allowClear
                style={{ width: "100%" }}
                placeholder="Chọn gói dịch vụ"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                onChange={caculateDataServices}
                options={listServicePack.filter(
                  (item) => !_.get(item, "isServicePackSupport")
                )}
              />
            </Form.Item>
            <Form.Item label={"Gói hỗ trợ"} name={"servicePackageSupportIds"}>
              <Select
                mode="multiple"
                showSearch
                allowClear
                style={{ width: "100%" }}
                placeholder="Chọn gói hỗ trợ"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                onChange={caculateDataServices}
                options={listServicePack.filter((item) =>
                  _.get(item, "isServicePackSupport")
                )}
              />
            </Form.Item>
            <Form.Item
              required
              label={"Thời gian"}
              name={"time"}
              rules={yupSync}
            >
              <InputNumber
                placeholder=""
                min={0}
                style={{ color: "#000" }}
                onChange={caculateDataServices}
              />
            </Form.Item>

            <Form.Item
              required
              label={"Ngày đăng ký"}
              name={"startDateContract"}
              rules={yupSync}
            >
              <DatePicker
                style={{ width: "100%", padding: "0px" }}
                allowClear
                placeholder="Chọn thời gian đăng ký"
                format={dateFormat}
                onChange={() => {
                  caculateDataServices();
                  getListPromotion(
                    form
                      .getFieldValue("startDateContract")
                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                  );
                }}
              />
            </Form.Item>

            <Form.Item label={"Chương trình KM"} name={"promotionId"}>
              <Select
                showSearch
                allowClear
                style={{ width: "100%" }}
                onChange={() => {
                  setTimeStart(!timeStart);
                  getListPromotionDetail(form.getFieldValue("promotionId"));
                  caculateDataServices();
                }}
                placeholder="Nhập tên chương trình KM để tìm kiếm"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={listPromotion}
              />
            </Form.Item>

            <Form.Item label={"Chi tiết KM"} name={"promotionDetailId"}>
              <Select
                showSearch
                allowClear
                style={{ width: "100%" }}
                onChange={() => {
                  setTimeStart(!timeStart);
                  caculateDataServices();
                }}
                placeholder="Nhập tên chi tiết KM để tìm kiếm"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={listPromotionDetail}
              />
            </Form.Item>
            <Form.Item label={"Giảm trừ"} name={"inputReduce"} rules={yupSync}>
              <InputNumber
                placeholder=""
                style={{ color: "#000" }}
                onChange={caculateDataServices}
              />
            </Form.Item>
            <Form.Item
              label={"Thời gian cảnh báo"}
              name={"endDateNotificationWarn"}
              rules={yupSync}
              required
            >
              <DatePicker
                format={dateFormat}
                placeholder="Chọn thời gian cảnh báo"
                style={{ color: "#000", width: "100%", padding: "0px" }}
              />
            </Form.Item>
            <Form.Item label={"Thời gian hết hạn"} name={"endDateContract"}>
              <DatePicker
                format={dateFormat}
                placeholder="Chọn thời gian hết hạn"
                disabled
                style={{ color: "#000", width: "100%", padding: "0px" }}
              />
            </Form.Item>
            <Form.Item
              label={"Thời gian đóng TK"}
              name={"endDateCloseAccount"}
              rules={yupSync}
              required
            >
              <DatePicker
                format={dateFormat}
                placeholder="Chọn thời gian đóng TK"
                style={{ color: "#000", width: "100%", padding: "0px" }}
              />
            </Form.Item>
            <hr />
            <div>
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                Tổng chi phí:{" "}
                {moneyFormat(
                  _.sum([
                    form.getFieldValue("totalMoneySubscriber") ?? 0,
                    form.getFieldValue("totalMoneySupport") ?? 0,
                  ]) - (form.getFieldValue("reduce") ?? 0)
                )}{" "}
                đ
              </span>
              <span> bao gồm</span>
              {/* {calculateCost()} */}
              {labelValue(
                "Tổng tiền gói thuê bao (số tháng x quy mô)",
                form.getFieldValue("totalMoneySubscriber") ?? 0
              )}
              {labelValue(
                "Tổng tiền gói hỗ trợ",
                form.getFieldValue("totalMoneySupport") ?? 0
              )}
              {labelValue(
                "Tổng tiền khuyến mại",
                (form.getFieldValue("reduce") ?? 0) -
                  (form.getFieldValue("inputReduce") ?? 0),
                true
              )}
              {labelValue(
                "Tổng tiền giảm trừ",
                form.getFieldValue("inputReduce") ?? 0,
                true
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="ant-modal-footer">
        <Button
          type="default"
          onClick={() => {
            if (!isAddServicePack) {
              closeModal(dispatch, setModal);
            } else {
              onClose();
            }
            form.resetFields();
          }}
        >
          Huỷ
        </Button>
        <Button
          onClick={() => {
            if (!loading) form.submit();
          }}
          type="primary"
          loading={loading}
        >
          {isAddServicePack ? "Xác nhận" : "Thêm mới"}
        </Button>
      </div>
    </Form>
  );
}

export default CUContract;
